import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { BiRightArrowAlt } from "react-icons/bi";
import { TfiYoutube } from "react-icons/tfi";
import { BsTelegram } from "react-icons/bs";
import styles from "./Footer.module.css";

const Footer = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <footer className={styles.footer}>
      <div className={styles.footerColumns}>
        <div className={styles.footerColumn}>
          <div className={styles.footerRow}>
            <div className={styles["individual-div"]}>
              <span onClick={() => navigate("/aboutus")} className={styles['aTag']}>About Us</span>
            </div>
          </div>
          <div className={styles.footerRow}>
            <div className={styles["individual-div"]}>
              <a href="https://play.google.com/store/apps/details?id=com.ssccgl.pinnacle.testportal" target="_blank" className={styles['aTag']}>Download App</a>
            </div>
          </div>
          <div className={styles.footerRow}>
            <div className={styles["individual-div"]}>
              <span onClick={() => navigate("/footer/Contact-Us")} className={styles['aTag']}>Contact Us</span>
            </div>
          </div>
        </div>

        <div className={styles.footerColumn}>
          <div className={styles.footerRow}>
            <div className={styles["individual-div"]}>
              <span onClick={() => navigate("/footer/Terms-and-Condition")} className={styles['aTag']}>Terms &amp; Conditions</span>
            </div>
          </div>
          <div className={styles.footerRow}>
            <div className={styles["individual-div"]}>
              <span onClick={() => navigate("/privacyPolicy")} className={styles['aTag']}>Privacy Policy</span>
            </div>
          </div>
          <div className={styles.footerRow}>
            <div className={styles["individual-div"]}>
              <span onClick={() => navigate("/footer/Refund-Policy")} className={styles['aTag']}>Refund Policy</span>
            </div>
          </div>
        </div>

        <div className={styles.footerColumn}>
          <div className={styles.footerRow}>
            <div className={styles["individual-div"]}>
              <span onClick={() => navigate("/digital-catalog")} className={styles['aTag']}>Printed Books</span>
            </div>
          </div>
          <div className={styles.footerRow}>
            <div className={styles["individual-div"]}>
              <span onClick={() => navigate("/books-catalog")} className={styles['aTag']}>Digital Books</span>
            </div>
          </div>
          <div className={styles.footerRow}>
            <div className={styles["individual-div"]}>
              <a href="https://testportal.ssccglpinnacle.com/" target="_blank" className={styles['aTag']}>Test Portal</a>
            </div>
          </div>
        </div>

        <div className={styles.footerColumn}>
          <TfiYoutube className={styles.youtubeicon} size={25} />
          <div className={styles.footerRow}>
            <div className={styles["individual-div"]}>
              <a href="https://www.youtube.com/channel/UCn3aOx3FoeRUzVhOHL5U5Mw" target="_blank" className={styles['aTag']}>YouTube Channel</a>
            </div>
          </div>
          <BsTelegram className={styles.telegramicon} size={25} />
          <div className={styles.footerRow}>
            <div className={styles["individual-div"]}>
              <a href="https://t.me/ssccglpinnacleonline" target="_blank" className={styles['aTag']}>Telegram Channel</a>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.footerCopyright}>
        <BiRightArrowAlt className={styles.footerCopyrightIcon} size={14} />
        <span>&copy; 2024 Pinnacle. All rights reserved.</span>
      </div>
    </footer>
  );
};

export default Footer;


// import React, { useEffect } from "react";
// import styles from "./Footer.module.css";
// import { BiGlobe, BiRightArrowAlt } from "react-icons/bi";
// import { TfiYoutube } from "react-icons/tfi";
// import { BsTelegram } from "react-icons/bs";
// import { useNavigate } from "react-router";

// const Footer = () => {
//   const navigate = useNavigate();
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);
//   return (
//     <footer className={styles.footer}>
//       <div className={styles.footerColumns}>
//         <div className={styles.footerColumn}>
//           <div
//             className={styles.footerRow}
//             // onClick={() => navigate("/aboutus")}
//           >
           
//             <div className={styles["individual-div"]}>
//             <a
//                 href="https://testportal.ssccglpinnacle.com/aboutus"
//                 target="_blank"
//                 rel="noopener noreferrer"
//                 // className={classes["aTag"]}
//               >
//                 About Us
//               </a>
//             </div>
//             {/* <div>
//               <a
//                 href="https://play.google.com/store/apps/details?id=com.ssccgl.pinnacle.testportal"
//                 target="_blank"
//                 rel="noopener noreferrer"
//                 // className={classes["aTag"]}
//               >
//                 Download App
//               </a>
//             </div> */}
//           </div>
//           <div
//             className={styles.footerRow}
//           >
//             <div className={styles["individual-div"]}><a href="https://play.google.com/store/apps/details?id=com.ssccgl.pinnacle.testportal" target="blank" className={styles['aTag']}>Download App</a></div>
//           </div>
//           <div
//             className={styles.footerRow}
//             // onClick={() => navigate("/contectus")}
//           >
//             <div className={styles["individual-div"]}><a href="https://testportal.ssccglpinnacle.com/contactus" target="blank" className={styles['aTag']}>Contact Us</a></div>
//           </div>
//         </div>

//         <div className={styles.footerColumn}>
//         <div
//   className={styles.footerRow}
//   // onClick={() => window.open("https://ssccglpinnacle.com/footer/Terms-and-Conditions", "_blank")}
// >
//   <div className={styles["individual-div"]}>
//   <a href="https://testportal.ssccglpinnacle.com/termandcondition" target="blank" className={styles['aTag']}>Terms &amp; Conditions</a>    
//   </div>
// </div>
//           <div
//             className={styles.footerRow}
//             onClick={() => navigate("/privacyPolicy")}
//           >
//             <div className={styles["individual-div"]} >
//             <a href="https://testportal.ssccglpinnacle.com/privacypolicy" target="blank" className={styles['aTag']}>Privacy Policy</a>
//             </div>
//           </div>
//           <div
//             className={styles.footerRow}
//             // onClick={() => navigate("/refundPolicy")}
//           >
//             <div className={styles["individual-div"]}>
//             <a href="https://testportal.ssccglpinnacle.com/refundPolicy" target="blank" className={styles['aTag']}>Refund Policy</a>
//             </div>
//           </div>
//         </div>

//         <div className={styles.footerColumn}>
//           <div className={styles.footerRow}>
//             <div className={styles["individual-div"]}><a href="https://ssccglpinnacle.com/books-catalog" target="blank" className={styles['aTag']}>Printed Books</a></div>
//           </div>
//           <div className={styles.footerRow}>
          
//             <div className={styles["individual-div"]}><a href="https://ssccglpinnacle.com/books-catalog" target="blank" className={styles['aTag']}>Digital Books</a></div>
//           </div>
//           <div className={styles.footerRow}>
//             <div className={styles["individual-div"]}><a href="https://testportal.ssccglpinnacle.com/" target="blank" className={styles['aTag']}>Test Portal</a></div>
//           </div>
//         </div>

//         <div className={styles.footerColumn}>
//           <TfiYoutube className={styles.youtubeicon} size={25} />
//           <div className={styles.footerRow}>
//             <div className={styles["individual-div"]}><a href="https://www.youtube.com/channel/UCn3aOx3FoeRUzVhOHL5U5Mw" target="blank" className={styles['aTag']}>YouTube Channel</a></div>
//           </div>
//           <BsTelegram className={styles.telegramicon} size={25} />
//           <div className={styles.footerRow}>
//             <div className={styles["individual-div"]}><a href="https://t.me/ssccglpinnacleonline" target="blank" className={styles['aTag']}>Telegram Channel</a></div>
//           </div>
//         </div>
//         <div className={styles.footerColumnlast}>
//           <BiGlobe className={styles.footerGlobeIcon} size={25} />
//           <div className={styles.footerLanguage}>English</div>
//         </div>
//       </div>

//       <div className={styles.footerCopyright}>
//         <BiRightArrowAlt className={styles.footerCopyrightIcon} size={14} />
//         <span>&copy; 2024 Pinnacle. All rights reserved.</span>
//       </div>
//     </footer>
//   );
// };

// export default Footer;
