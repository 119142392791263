


import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Row1Digital.css";

function Row1Digital({ onDataFiltered }) {
  const [cardData, setCardData] = useState([]);
  const [cardDataexam, setCardDataexam] = useState([]);
  const [filters, setFilters] = useState({
    ssc: false,
    railway: false,
    teaching: false,
  });
  const [examFilters, setExamFilters] = useState({});
  const [examNames, setExamNames] = useState([]);
  const [examNamesa, setExamNamesa] = useState([]);
  const [examNameFilters, setExamNameFilters] = useState(false);
  const [examCategoryFilters, setExamCategoryFilters] = useState(false);

  const [previousData, setPreviousData] = useState([]);
  const [firstfilterdata, setfirstfilterdata] = useState(true);
  const [secondfilterdata, setsecondfilterdata] = useState([]);
  const [languageFilters, setLanguageFilters] = useState({
    english: false,
    hindi: false,
    bilingual: false,
    other: false,
  });
  const [subjectFilters, setSubjectFilters] = useState({
    maths: false,
    english: false,
    reasoning: false,
    ga: false,
    currentAffairs: false,
    // science: false,
    all: false,
  });

  // console.log("first filter:" , firstfilterdata);
  // console.log("second filter:" ,secondfilterdata);

  

  const fetchData = async (updateExamNames = false) => {
    try {
      const ebookResponse = await fetch(
        "https://auth.ssccglpinnacle.com/api/ebooksforactive?active=true"
      );
      const examResponse = await fetch("https://auth.ssccglpinnacle.com/api/examnames-ebook");
    
      if (!ebookResponse.ok || !examResponse.ok) {
        throw new Error(`Failed to fetch data`);
      }
    
      const ebookData = await ebookResponse.json();
      const examData = await examResponse.json();
      ebookData.sort((a, b) => a.order - b.order);
        // setCardData(ebookData);
      setCardDataexam(ebookData);
    
      const filteredData = ebookData.filter((item) => {
        const categoryFilterMatch =
          !Object.values(filters).some(Boolean) ||
          Object.entries(filters).some(
            ([key, value]) =>
              value && item.category.toLowerCase().includes(key.toLowerCase())
          );
    
        const examFilterMatch =
          !Object.values(examFilters).some(Boolean) ||
          Object.entries(examFilters).some(([key, value]) => {
            if (value) {
              const formattedKey = key.replace(/\s/g, "").toLowerCase();
              const formattedExamName = item.examName
                .replace(/\s/g, "")
                .toLowerCase();
              return formattedExamName.includes(formattedKey);
            }
            return false;
          });
    
        const languageFilterMatch =
          !Object.values(languageFilters).some(Boolean) ||
          Object.entries(languageFilters).some(([key, value]) => {
            if (value) {
              return item.medium.toLowerCase() === key.toLowerCase();
            }
            return false;
          });
    
          const subjectFilterMatch =
        !Object.values(subjectFilters).some(Boolean) ||
        Object.entries(subjectFilters).some(([key, value]) => {
          if (value && item.subject) {
            const subjectString = item.subject.toLowerCase();
            return (
              subjectString.includes(key.toLowerCase()) ||
              (key === "ga" &&
                (subjectString.includes("gk") ||
                  subjectString.includes("general studies") ||
                  subjectString.includes("gs"))) ||
              (key === "science" && subjectString.includes("science"))
            );
          }
          return false;
        });
    
        // Custom filtering for Railway category
        if (filters.railway) {
          return (
            categoryFilterMatch &&
            examFilterMatch &&
            languageFilterMatch &&
            subjectFilterMatch &&
            item.examName.split(',').some((name) => {
              const formattedName = name.trim().toLowerCase();
              return formattedName.startsWith("r") || formattedName.includes("n");
            })
          );
        }
    
        // Custom filtering for SSC category
        if (filters.ssc) {
          return (
            categoryFilterMatch &&
            examFilterMatch &&
            languageFilterMatch &&
            subjectFilterMatch &&
            item.examName.split(',').some((name) => {
              const formattedName = name.trim().toLowerCase();
              return formattedName.includes("ssc") || formattedName.startsWith("m");
            })
          );
        }
    
        // Custom filtering for Teaching category
        if (filters.teaching) {
          return (
            categoryFilterMatch &&
            examFilterMatch &&
            languageFilterMatch &&
            subjectFilterMatch &&
            item.category.toLowerCase().includes("teaching")
          );
        }
    
        return categoryFilterMatch && examFilterMatch && languageFilterMatch && subjectFilterMatch;
      });
    
      setCardData(filteredData);
    
      // Update exam names only if categories change
      if (updateExamNames) {
        const uniqueExamNames = [
          ...new Set(filteredData.flatMap((item) => {
            return item.examName.split(",").filter((name) => {
              const formattedName = name.trim().toLowerCase();
              if (filters.railway) {
                return formattedName.startsWith("r") || formattedName.includes("n");
              } else if (filters.ssc) {
                return formattedName.includes("ssc") || formattedName.startsWith("m");
              } else {
                return true;
              }
            });
          })),
        ].map((name) => name.trim());
    
        setExamNames(uniqueExamNames);
      }
    
      if (firstfilterdata) {
        setfirstfilterdata(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  
  useEffect(() => {
    fetchData(false);  // Fetch data but do not update exam names
  }, [languageFilters, subjectFilters, examFilters]);
  
  useEffect(() => {
    fetchData(true);  // Update exam names when the category filter changes
  }, [filters]);  // Only trigger exam name update on category change
  
  
  
 
  
  const handleCheckboxChange = async (filter, isCategoryFilter = true) => {
    if (isCategoryFilter) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [filter]: !prevFilters[filter],
      }));
      setfirstfilterdata(isCategoryFilter);
      fetchData(true);  // Update exam names only when category changes
    } else {
      fetchData();  // Do not update exam names for other changes
    }
  };
  
  
  const handleExamNameCheckboxChange = (examName) => {
    // Toggle the filter state for the selected exam name
    setExamFilters((prevExamFilters) => ({
      ...prevExamFilters,
      [examName.toLowerCase().replace(/\s+/g, "")]:
        !prevExamFilters[examName.toLowerCase().replace(/\s+/g, "")],
    }));
  
    // Re-fetch filtered data but do not update the exam names list
    fetchData(false);
  };
  
  
  
  
  
// useEffect(() => {
//     fetchData();
//   }, [filters, examFilters, languageFilters, subjectFilters]);// Include both filters in the dependency array

  // const handleCheckboxChange = async (filter, isCategoryFilter = true) => {
  //   if (isCategoryFilter) {
  //     setFilters((prevFilters) => ({
  //       ...prevFilters,
  //       [filter]: !prevFilters[filter],
  //     }));
  //     setfirstfilterdata(isCategoryFilter);
  //     fetchData();
  //   } else {
  //     // Handle other checkbox changes if needed
  //   }
  // };

  // const handleExamNameCheckboxChange = (examName) => {
  //   // Toggle the filter state for the selected exam name
  //   setExamFilters((prevExamFilters) => ({
  //     ...prevExamFilters,
  //     [examName.toLowerCase().replace(/\s+/g, "")]:
  //       !prevExamFilters[examName.toLowerCase().replace(/\s+/g, "")],
  //   }));
  // };

  const handleLanguageCheckboxChange = (language) => {
    setLanguageFilters((prevLanguageFilters) => ({
      ...prevLanguageFilters,
      [language.toLowerCase()]: !prevLanguageFilters[language.toLowerCase()],
    }));
  };

  const handleSubjectCheckboxChange = (subject) => {
    setSubjectFilters((prevSubjectFilters) => ({
      ...prevSubjectFilters,
      [subject.toLowerCase()]: !prevSubjectFilters[subject.toLowerCase()],
    }));
  };
  // Log cardData when it changes
  useEffect(() => {
    setPreviousData(cardData);
   console.log(cardData)
   onDataFiltered(cardData)
  }, [cardData]);

  return (
    <>
      <Col className="Row1digital" sm={3} md={3} xl={3} xs={3}>
        <Col className="For_filters nopadding" sm={12} md={12} xl={12} xs={12}>
          <p className="section-suggest-digital-second-p">Home | ebooks</p>
          <Col
            className="Category-Col nopadding"
            md={12}
            sm={12}
            xs={12}
            xl={12}
          >
            <Row className="Category-1st nopadding" md={8} sm={8} xs={8} xl={8}>
              <p className="para_category"> Category </p>
            </Row>
            <Row
              className="Category-2nd nopadding"
              md={12}
              sm={12}
              xs={12}
              xl={12}
            >
              {Object.keys(filters).map((filter, index) => (
                <React.Fragment key={index}>
                  <div className="for-padding-input">
                  <input
                    type="checkbox"
                    id={`filter_${index}`}
                    name={`filter_${index}`}
                    checked={filters[filter]}
                    onChange={() => handleCheckboxChange(filter)}
                  />
                  <label htmlFor={`filter_${index}`}>
                    {filter.toUpperCase()}
                  </label>
                  <br /></div>
                </React.Fragment>
              ))}
            </Row>
          </Col>
          <Col className="Exam-Name nopadding" md={12} sm={12} xs={12} xl={12}>
            <Row className="Category-1st nopadding" md={8} sm={8} xs={8} xl={8}>
              <p className="para_category"> Exam Name </p>
            </Row>
            <Row
              className="Category-4nd nopadding"
              md={12}
              sm={12}
              xs={12}
              xl={12}
            >
             {[...new Set(examNames)]
      .filter(examName => examName && examName !== "undefined" && examName !== "null")  // Filter out invalid values
      .map((examName, index) => (
                <React.Fragment key={index}>
                   <div className="for-padding-input">
                  <input
                    type="checkbox"
                    id={`exam_${index}`}
                    name={`exam_${index}`}
                    checked={
                      examFilters[examName.toLowerCase().replace(/\s+/g, "")]
                    }
                    onChange={() => handleExamNameCheckboxChange(examName)} // Pass examName directly
                  />
                  <label htmlFor={`exam_${index}`}>{examName}</label>
                  <br /></div>
                </React.Fragment>
              ))}
            </Row>
          </Col>
          <Col className="Language nopadding" md={12} sm={12} xs={12} xl={12}>
            <Row className="Category-1st nopadding">
              <p className="para_category"> Language </p>
            </Row>
            <Row
              className="Category-2nd nopadding"
              md={12}
              sm={12}
              xs={12}
              xl={12}
            >
                <div className="for-padding-input">
              <input
                type="checkbox"
                id="english"
                name="english"
                value="english"               
                onChange={() => handleLanguageCheckboxChange("English")}
              />
              <label htmlFor="english">English </label>
              <br /></div>
              <div className="for-padding-input">
              <input
                type="checkbox"
                id="hindi"
                name="hindi"
                value="hindi"
               
                onChange={() => handleLanguageCheckboxChange("Hindi")}
              />
              <label htmlFor="hindi">Hindi</label>
              <br /></div>
              <div className="for-padding-input">
              <input
                type="checkbox"
                id="bilingual"
                value="bilingual"
                
                onChange={() => handleLanguageCheckboxChange("Bilingual")}
              />
              <label htmlFor="bilingual">Bilingual</label>
              <br /></div>
              <div className="for-padding-input">
              <input
                type="checkbox"
                id="other"
                name="other"
                value="other"
                
                onChange={() => handleLanguageCheckboxChange("Other")}
              />
              <label htmlFor="other">Other</label>
              <br /></div>
            </Row>
          </Col>
          <Col className="Subject nopadding" md={12} sm={12} xs={12} xl={12}>
  <Row className="Category-1st nopadding" md={8} sm={8} xs={8} xl={8}>
    <p className="para_category"> Subject </p>
  </Row>
  <Row className="Category-3nd nopadding" md={12} sm={12} xs={12} xl={12}>
    <input
      type="checkbox"
      id="maths"
      name="maths"
      value="maths"
      onChange={() => handleSubjectCheckboxChange("Maths")}
    />
    <label htmlFor="maths">Maths</label>
    <br />
    <input
      type="checkbox"
      id="english"
      name="english"
      value="english"
      onChange={() => handleSubjectCheckboxChange("English")}
    />
    <label htmlFor="english">English</label>
    <br />

    <input
      type="checkbox"
      id="reasoning"
      name="reasoning"
      value="reasoning"
      onChange={() => handleSubjectCheckboxChange("Reasoning")}
    />
    <label htmlFor="reasoning">Reasoning</label>
    <br />

    <input
      type="checkbox"
      id="ga"
      name="ga"
      value="ga"
      onChange={() => handleSubjectCheckboxChange("GA")}
    />
    <label htmlFor="ga">GA</label>
    <br />

    <input
      type="checkbox"
      id="currentAffairs"
      name="currentAffairs"
      value="currentAffairs"
      onChange={() => handleSubjectCheckboxChange("Current Affairs")}
    />
    <label htmlFor="currentAffairs">Current Affairs</label>
    <br />
    {/* <input
      type="checkbox"
      id="currentAffairs"
      name="science"
      value="science"
      onChange={() => handleSubjectCheckboxChange("science")}
    />
    <label htmlFor="currentAffairs">Science</label>
    <br /> */}
    
 
  </Row>
</Col>

        </Col>
      </Col>
    </>
  );
}

export default Row1Digital;
