import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./YT_PlayerHeader.module.css";
import {
  IoIosShareAlt,
  IoLogoFacebook,
  IoLogoTwitter,
  IoIosMail,
  IoLogoWhatsapp,
  IoMdClose,
} from "react-icons/io";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Logo from "../../assests/pinnacleWhiteLogo.svg"

const YTPlayerHeader = ({ courseId }) => {
  const [courseTitle, setCourseTitle] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isShareModalOpen, setShareModalOpen] = useState(false);

  const navigate = useNavigate();

  const shareUrl = `https://home.ssccglpinnacle.com/coursedescription/${courseId}`;
  const shareText = `Check out this course I'm taking on ${courseTitle}! ${shareUrl}`;

  const handleShareClick = () => {
    setShareModalOpen(true);
    console.log("Share button clicked");
  };

  const closeShareModal = () => {
    setShareModalOpen(false);
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(shareUrl);
    alert("Link copied to clipboard");
  };

  useEffect(() => {
    const fetchCourseTitle = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `https://auth.ssccglpinnacle.com/course/${courseId}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch course details");
        }
        const data = await response.json();
        setCourseTitle(data.courseTitle);
        setIsLoading(false); // Only stop loading if the fetch was successful
      } catch (error) {
        console.error(error.message);
        // Do not set isLoading to false here, so the loading state continues
      }
    };

    if (courseId) {
      fetchCourseTitle();
    }
  }, [courseId]);

  const shareOnWhatsApp = () => {
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(shareText)}`;
    window.open(whatsappUrl, "_blank");
  };

  const shareOnTwitter = () => {
    const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareText)}`;
    window.open(twitterUrl, "_blank");
  };

  const shareOnFacebook = () => {
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}&quote=${encodeURIComponent(shareText)}`;
    window.open(facebookUrl, "_blank");
  };

  return (
    <div className={styles.courseHeader}>
      <img
        src={Logo}
        alt="Logo"
        className={styles.logo}
        onClick={() => navigate("/videos")}
        style={{ cursor: "pointer" }}
      />
       <h3 className={styles.courseTitle}>
        {isLoading ? <Skeleton width="100%" /> : courseTitle}
      </h3>
      <button onClick={handleShareClick} className={styles.shareButton}>
        Share <IoIosShareAlt size={20} />
      </button>

      {isShareModalOpen && (
        <div className={styles.shareModalOverlay}
          onClick={(e) => {
            if (e.target.classList.contains(styles.shareModalOverlay)) {
              closeShareModal(); // Close the modal if the backdrop is clicked
            }}}>
          <div className={styles.shareModal}>
            <div className={styles.shareModalHeader}>
              <h2>Share this course</h2>
              <button onClick={closeShareModal} className={styles.closeButton}>
                <IoMdClose size={20} />
              </button>
            </div>
            <div className={styles.urlCopy}>
              <input
                type="text"
                value={shareUrl}
                readOnly
                className={styles.shareInput}
              />
              <button onClick={handleCopyClick} className={styles.copyButton}>
                Copy
              </button>
            </div>
            <div className={styles.socialMediaIcons}>
              <button className={styles.iconButton} onClick={shareOnFacebook}>
                <IoLogoFacebook className={styles.mediaIcons} size={20} />
              </button>
              <button className={styles.iconButton} onClick={shareOnTwitter}>
                <IoLogoTwitter className={styles.mediaIcons} size={20} />
              </button>
              <button className={styles.iconButton} onClick={shareOnWhatsApp}>
                <IoLogoWhatsapp className={styles.mediaIcons} size={20} />
              </button>
              <button className={styles.iconButton}>
                <IoIosMail size={20} className={styles.mediaIcons} />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default YTPlayerHeader;
