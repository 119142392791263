import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import styles from "./Cart.module.css";
import { useNavigate } from "react-router-dom";
import ApplyCoupon from "./ApplyCoupon";
import Share from "./ShareComponent/Share";
import { IoHeart } from "react-icons/io5";
import { FaRegHeart } from "react-icons/fa6";
import { useAuth } from "../../Context/AuthContext";
import { useCookies } from "react-cookie";
import LoginModal from "./LoginModal";
import Footer from "../Footer/Footer";

const Cart = ({ courseId }) => {
  const navigate = useNavigate();
  const [showCoupon, setShowCoupon] = useState(false);
  const [isShare, setIsShare] = useState(false);
  const [courseTitle, setCourseTitle] = useState("");
  const [courseDetails, setCourseDetails] = useState("");
  const [teacherName, setTeacherName] = useState("");
  const [rating, setRating] = useState(0);
  const [price, setPrice] = useState(0);
  const [mrp, setMrp] = useState(0);
  const [hindiImage, setHindiImage] = useState("");
  const [EnglishImage, setEnglishImage] = useState("");
  const [isAddedToWishlist, setIsAddedToWishlist] = useState(false);
  const { isLoggedIn, user } = useAuth();
  const [cookies] = useCookies(["token", "email_id"]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchCourseDetails = async () => {
      try {
        const response = await fetch(
          `http://localhost:5000/course/${courseId}`
        );
        if (!response.ok) {
          throw new Error(
            `Failed to fetch course details. Status: ${response.status}`
          );
        }
        const result = await response.json();
        setCourseTitle(result.courseTitle || "Course Title");
        setCourseDetails(result.shortDescription || "No description available");
        setTeacherName(result.instructorName || "Instructor");
        setRating(result.rating || 0);
        setPrice(result.price || 0);
        setMrp(result.mrp || 0);
        setHindiImage(result.hindiCoverImage || "");
        setEnglishImage(result.englishCoverImage || "");
      } catch (error) {
        console.error("Error fetching course details:", error);
      }
    };

    const fetchWishlist = async () => {
      if (!user || !user._id) {
        console.error("User not found");
        return;
      }

      try {
        const response = await fetch(
          `http://localhost:5000/api/wishlist/${user._id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${cookies.token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(
            `Failed to fetch wishlist. Status: ${response.status}`
          );
        }

        const wishlistData = await response.json();
        const isWishlisted =
          Array.isArray(wishlistData) &&
          wishlistData.some((item) => item._id === courseId);
        setIsAddedToWishlist(isWishlisted);
      } catch (error) {
        console.error("Error checking wishlist status:", error);
      }
    };

    fetchCourseDetails();
    fetchWishlist();
  }, [courseId, user, cookies.token]);

  const handleShare = () => {
    setIsShare(!isShare);
  };

  const ApplyCouponHandler = () => {
    setShowCoupon(!showCoupon);
  };

  const getPrice = () => {
    return price ? Math.round(price) : 0;
  };

  const wishlistHandler = async () => {
    if (!user?._id) {
      setShowModal(true);
      return;
    }

    try {
      const response = await fetch(
        `http://localhost:5000/api/wishlist/${user._id}/${courseId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.token}`,
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        setIsAddedToWishlist(true);
        Swal.fire({
          title: "Success!",
          text: "Added to Wishlist",
          icon: "success",
          confirmButtonText: "OK",
        });
      } else {
        Swal.fire({
          title: "Failed",
          text: data.error || "Failed to add course to wishlist",
          icon: "error",
          confirmButtonText: "OK",
        });
        console.error("Failed to add course to wishlist", data);
      }
    } catch (error) {
      console.error("Error adding course to wishlist:", error);
    }
  };

  const cartHandler = async () => {
    if (!isLoggedIn) {
      setShowModal(true);
      return;
    }

    if (!cookies.token) {
      Swal.fire({
        title: "Access Denied",
        text: "You do not have access to add this course to the cart.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    try {
      // Check if the course is already purchased
      const purchasedResponse = await fetch(
        `http://localhost:5000/api/user_purchased_videos/${user._id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.token}`,
          },
        }
      );
      const purchasedData = await purchasedResponse.json();

      const isCoursePurchased =
        purchasedData.purchasedVideos?.some(
          (video) => video.productId === courseId
        ) || false;

      if (isCoursePurchased) {
        Swal.fire({
          title: "Course Already Purchased",
          text: "You have already purchased this course. Redirecting to My Learning.",
          icon: "info",
          confirmButtonText: "Go to My Learning",
        }).then(() => {
          navigate(`/mylearning/${user._id}`);
        });
        return;
      }

      // console.log("Adding to cart for user:", user._id);
      const response = await fetch(
        `http://localhost:5000/api/cart/${user._id}/${courseId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.token}`,
          },
        }
      );
      const data = await response.json();

      if (response.status === 200 && data.info) {
        Swal.fire({
          title: "Info",
          text: data.info,
          icon: "info",
          confirmButtonText: "OK",
        });
      } else if (response.status === 201) {
        Swal.fire({
          title: "Success!",
          text: data.message,
          icon: "success",
          confirmButtonText: "OK",
        });
      } else {
        Swal.fire({
          title: "Failed",
          text: data.error || "Failed to add course to cart",
          icon: "error",
          confirmButtonText: "OK",
        });
        console.error("Failed to add course to cart", data);
      }
    } catch (error) {
      console.error("Error adding course to cart:", error);
    }
  };

  const buyBtnHandler = async () => {
    if (!isLoggedIn) {
      setShowModal(true);
      return;
    }

    try {
      const purchasedResponse = await fetch(
        `http://localhost:5000/api/user_purchased_videos/${user._id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.token}`,
          },
        }
      );

      const purchasedData = await purchasedResponse.json();
      const isCoursePurchased =
        purchasedData.purchasedVideos?.some(
          (video) => video.productId === courseId
        ) || false;

      if (isCoursePurchased) {
        Swal.fire({
          title: "Course Already Purchased",
          text: "You have already purchased this course. Redirecting to My Learning.",
          icon: "info",
          confirmButtonText: "Go to My Learning",
        }).then(() => {
          navigate(`/mylearning/${user._id}`);
        });
        return;
      }

      const response = await fetch(
        `http://localhost:5000/api/cart/${user._id}/${courseId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.token}`,
          },
        }
      );

      const data = await response.json();

      if (
        response.status === 201 ||
        (response.status === 200 && data.info === "Course already in cart")
      ) {
        navigate("/videos/cart");
      } else {
        Swal.fire({
          title: "Failed",
          text: data.error || "Failed to add course to cart",
          icon: "error",
          confirmButtonText: "OK",
        });
        console.error("Failed to add course to cart", data);
      }
    } catch (error) {
      console.error("Error adding course to cart:", error);
    }
  };

  const handleGift = () => {
    Swal.fire({
      title: "Info",
      text: "This feature will be coming soon.",
      icon: "info",
      confirmButtonText: "OK",
    });
  };
  const handleApplyCoupon = () => {
    Swal.fire({
      title: "Info",
      text: "This feature will be coming soon.",
      icon: "info",
      confirmButtonText: "OK",
    });
  };
  return (
    <>
      <div className={styles["above-cart-fullpage"]}>
        <div className={styles["cart-fullpage"]}>
          <div className={styles["image-section"]}>
            <img
              src={hindiImage}
              alt="Course in Hindi"
              className={styles["image1"]}
              id="cart-image1"
            />
            <img
              src={EnglishImage}
              alt="Course in English"
              className={styles["image2"]}
              id="cart-image2"
            />
          </div>
          <div className={styles.overlay}>
            <h2 className={styles.heading}>{courseTitle}</h2>
            <div className={styles["Months-price-section"]}>
              <span className={styles.months}>Duration: 12months</span>
              <div className={styles.gst}>18% GST included</div>
              <span className={styles.price}>Price: ₹{getPrice()}</span>
            </div>
            <button className={styles["buyBtn"]} onClick={buyBtnHandler}>
              Buy This Course
            </button>
            <div className={styles["cart-wishlist-Btn-div"]}>
              <button className={styles["cartBtn"]} onClick={cartHandler}>
                Add To Cart
              </button>
              <button
                className={styles["wishListBtn"]}
                onClick={wishlistHandler}
              >
                {isAddedToWishlist ? (
                  <IoHeart size={20} color="red" />
                ) : (
                  <FaRegHeart size={20} />
                )}
              </button>
            </div>
            <div className={styles["buttons-section"]}>
              <button
                className={styles["individual-btn"]}
                onClick={handleShare}
              >
                Share
              </button>
              <button className={styles["individual-btn"]} onClick={handleGift}>
                Gift this course
              </button>
              <button
                className={styles["individual-btn"]}
                onClick={handleApplyCoupon}
              >
                Apply coupon
              </button>
            </div>
            {/* {showCoupon && <ApplyCoupon />} */}
          </div>
          {isShare && <Share />}
        </div>
        <LoginModal show={showModal} handleClose={() => setShowModal(false)} />
      </div>
    </>
  );
};

export default Cart;
// import React, { useState, useEffect } from "react";
// import Swal from "sweetalert2";
// import styles from "./Cart.module.css";
// import { useNavigate } from "react-router-dom";
// import ApplyCoupon from "./ApplyCoupon";
// import Share from "./ShareComponent/Share";
// import { IoHeart } from "react-icons/io5";
// import { FaRegHeart } from "react-icons/fa6";
// import { useAuth } from "../../Context/AuthContext";
// import { useCookies } from "react-cookie";
// import LoginModal from "./LoginModal";
// import Footer from "../Footer/Footer";

// const Cart = ({ courseId }) => {
//   const navigate = useNavigate();
//   const [showCoupon, setShowCoupon] = useState(false);
//   const [isShare, setIsShare] = useState(false);
//   const [courseTitle, setCourseTitle] = useState("");
//   const [courseDetails, setCourseDetails] = useState("");
//   const [teacherName, setTeacherName] = useState("");
//   const [rating, setRating] = useState(0);
//   const [price, setPrice] = useState(0);
//   const [mrp, setMrp] = useState(0);
//   const [hindiImage, setHindiImage] = useState("");
//   const [EnglishImage, setEnglishImage] = useState("");
//   const [isAddedToWishlist, setIsAddedToWishlist] = useState(false);
//   const { isLoggedIn, user } = useAuth();
//   const [cookies] = useCookies(["token", "email_id"]);
//   const [showModal, setShowModal] = useState(false);

//   useEffect(() => {
//     const fetchCourseDetails = async () => {
//       try {
//         const response = await fetch(
//           `http://localhost:5000/course/${courseId}`
//         );
//         if (!response.ok) {
//           throw new Error(
//             `Failed to fetch course details. Status: ${response.status}`
//           );
//         }
//         const result = await response.json();
//         setCourseTitle(result.courseTitle || "Course Title");
//         setCourseDetails(result.shortDescription || "No description available");
//         setTeacherName(result.instructorName || "Instructor");
//         setRating(result.rating || 0);
//         setPrice(result.price || 0);
//         setMrp(result.mrp || 0);
//         setHindiImage(result.hindiCoverImage || "");
//         setEnglishImage(result.englishCoverImage || "");
//       } catch (error) {
//         console.error("Error fetching course details:", error);
//       }
//     };

//     const fetchWishlist = async () => {
//       if (!user || !user.email_id) {
//         console.error("User not found");
//         return;
//       }

//       try {
//         const response = await fetch(
//           `http://localhost:5000/api/wishlist/${user.email_id}`,
//           {
//             headers: {
//               "Content-Type": "application/json",
//               Authorization: `Bearer ${cookies.token}`,
//             },
//           }
//         );

//         if (!response.ok) {
//           throw new Error(
//             `Failed to fetch wishlist. Status: ${response.status}`
//           );
//         }

//         const wishlistData = await response.json();
//         const isWishlisted =
//           Array.isArray(wishlistData) &&
//           wishlistData.some((item) => item._id === courseId);
//         setIsAddedToWishlist(isWishlisted);
//       } catch (error) {
//         console.error("Error checking wishlist status:", error);
//       }
//     };

//     fetchCourseDetails();
//     fetchWishlist();
//   }, [courseId, user, cookies.token]);

//   const wishlistHandler = async () => {
//     if (!user?.email_id) {
//       setShowModal(true);
//       return;
//     }

//     try {
//       const response = await fetch(
//         `http://localhost:5000/api/wishlist/${user.email_id}/${courseId}`,
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${cookies.token}`,
//           },
//         }
//       );
//       const data = await response.json();
//       if (response.ok) {
//         setIsAddedToWishlist(true);
//         Swal.fire({
//           title: "Success!",
//           text: "Added to Wishlist",
//           icon: "success",
//           confirmButtonText: "OK",
//         });
//       } else {
//         Swal.fire({
//           title: "Failed",
//           text: data.error || "Failed to add course to wishlist",
//           icon: "error",
//           confirmButtonText: "OK",
//         });
//         console.error("Failed to add course to wishlist", data);
//       }
//     } catch (error) {
//       console.error("Error adding course to wishlist:", error);
//     }
//   };

//   const cartHandler = async () => {
//     if (!isLoggedIn) {
//       setShowModal(true);
//       return;
//     }

//     if (!cookies.token) {
//       Swal.fire({
//         title: "Access Denied",
//         text: "You do not have access to add this course to the cart.",
//         icon: "warning",
//         confirmButtonText: "OK",
//       });
//       return;
//     }

//     try {
//       const purchasedResponse = await fetch(
//         `http://localhost:5000/api/user_purchased_videos/${user.email_id}`,
//         {
//           method: "GET",
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${cookies.token}`,
//           },
//         }
//       );
//       const purchasedData = await purchasedResponse.json();

//       const isCoursePurchased =
//         purchasedData.purchasedVideos?.some(
//           (video) => video.productId === courseId
//         ) || false;

//       if (isCoursePurchased) {
//         Swal.fire({
//           title: "Course Already Purchased",
//           text: "You have already purchased this course. Redirecting to My Learning.",
//           icon: "info",
//           confirmButtonText: "Go to My Learning",
//         }).then(() => {
//           navigate(`/mylearning/${user.email_id}`);
//         });
//         return;
//       }

//       const response = await fetch(
//         `http://localhost:5000/api/cart/${user.email_id}/${courseId}`,
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${cookies.token}`,
//           },
//         }
//       );
//       const data = await response.json();

//       if (response.status === 200 && data.info) {
//         Swal.fire({
//           title: "Info",
//           text: data.info,
//           icon: "info",
//           confirmButtonText: "OK",
//         });
//       } else if (response.status === 201) {
//         Swal.fire({
//           title: "Success!",
//           text: data.message,
//           icon: "success",
//           confirmButtonText: "OK",
//         });
//       } else {
//         Swal.fire({
//           title: "Failed",
//           text: data.error || "Failed to add course to cart",
//           icon: "error",
//           confirmButtonText: "OK",
//         });
//         console.error("Failed to add course to cart", data);
//       }
//     } catch (error) {
//       console.error("Error adding course to cart:", error);
//     }
//   };

//   // Rest of the code remains the same

//   return (
//     <>
//       <div className={styles["above-cart-fullpage"]}>
//         <div className={styles["cart-fullpage"]}>
//           <div className={styles["image-section"]}>
//             <img
//               src={hindiImage}
//               alt="Course in Hindi"
//               className={styles["image1"]}
//               id="cart-image1"
//             />
//             <img
//               src={EnglishImage}
//               alt="Course in English"
//               className={styles["image2"]}
//               id="cart-image2"
//             />
//           </div>
//           <div className={styles.overlay}>
//             <h2 className={styles.heading}>{courseTitle}</h2>
//             <div className={styles["Months-price-section"]}>
//               <span className={styles.months}>Duration: 12months</span>
//               <div className={styles.gst}>18% GST included</div>
//               <span className={styles.price}>Price: ₹{getPrice()}</span>
//             </div>
//             <button className={styles["buyBtn"]} onClick={buyBtnHandler}>
//               Buy This Course
//             </button>
//             <div className={styles["cart-wishlist-Btn-div"]}>
//               <button className={styles["cartBtn"]} onClick={cartHandler}>
//                 Add To Cart
//               </button>
//               <button
//                 className={styles["wishListBtn"]}
//                 onClick={wishlistHandler}
//               >
//                 {isAddedToWishlist ? (
//                   <IoHeart size={20} color="red" />
//                 ) : (
//                   <FaRegHeart size={20} />
//                 )}
//               </button>
//             </div>
//             <div className={styles["buttons-section"]}>
//               <button
//                 className={styles["individual-btn"]}
//                 onClick={handleShare}
//               >
//                 Share
//               </button>
//               <button className={styles["individual-btn"]} onClick={handleGift}>
//                 Gift this course
//               </button>
//               <button
//                 className={styles["individual-btn"]}
//                 onClick={handleApplyCoupon}
//               >
//                 Apply coupon
//               </button>
//             </div>
//             {/* {showCoupon && <ApplyCoupon />} */}
//           </div>
//           {isShare && <Share />}
//         </div>
//         <LoginModal show={showModal} handleClose={() => setShowModal(false)} />
//       </div>
//     </>
//   );
// };

// export default Cart;
