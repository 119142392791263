import React, { useState, useEffect } from "react";
import classes from "./Account.module.css";
import Card from "./Card/Card";
import { Link } from "react-router-dom";
import { useAuth } from "../../../Context/AuthContext";

function Account() {
  const { logout, user } = useAuth();
  // console.log("useAuth",user)
  const [showCard, setShowCard] = useState(false);

  useEffect(() => {
    // console.log("UserData Videos", user);
  }, [user]);

  const handleMouseEnter = () => {
    setShowCard(true);
  };
  const handleMouseLeave = () => {
    setShowCard(false);
  };

  const getInitials = (str) => {
    if (!str) return "";
    return str
      .split(" ")
      .map((word) => (word[0] ? word[0].toUpperCase() : ""))
      .join("");
  };

  return (
    <div className={classes["Account-wraper"]}>
      <div
        className={classes.dropdown}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <button className={classes.account_btn}>
          <div className={classes.account_icon_head}>
            <div className={classes.account_para1}>
              {getInitials(user?.full_name)}
            </div>
          </div>
        </button>
        {showCard && (
          <Card className={classes.account_container}>
            <div className={classes.accountsection}>
              <div className={classes.account_icon}>
                <div className={classes.userName_initials}>
                  {getInitials(user?.full_name)}
                </div>
              </div>

              <div className={classes.user}>
                {user?.full_name}
                <div className={classes.user_email}>{user?.email_id}</div>
              </div>
            </div>
            <ul className={classes.unordered_list1}>
              <Link
                to={`/mylearning/${user?._id}`}
                className={classes.list_link}
              >
                <li className={classes.list1}>My learning</li>
              </Link>
              <Link to="/videos/cart" className={classes.list_link}>
                <li className={classes.list1}>My Cart</li>
              </Link>
              <Link to="/videos/wishlist" className={classes.list_link}>
                <li className={classes.list1}>Wishlist</li>
              </Link>
              <li className={classes.list5} onClick={logout}>
                Log out
              </li>
            </ul>
            {/* <hr />
                        <ul className={classes.unordered_list5}>
                            <li className={classes.list5} onClick={logout}>Log out</li>
                        </ul> */}
          </Card>
        )}
      </div>
    </div>
  );
}

export default Account;
