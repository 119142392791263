// import React, { useEffect, useState, useContext } from "react";
// import { useParams, Navigate, useNavigate } from "react-router-dom";
// import HomeEbook from "./Ebook-Page/Home";
// import { ThemeContext } from "../../Context/ThemeContext";
// import "./Ebook-Page/Navigation.css";

// import Footer from "../../component/Footer/Footer";
// import { useAuth } from "../../Context/AuthContext";
// import Swal from "sweetalert2";
// import LoadingSpinner from "../LoadingSpinner";

// const DigitalMainPage = () => {
//   const { cardId } = useParams();
//   const [chapterData, setChapterData] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);
//   const { theme } = useContext(ThemeContext);
//   const navigate = useNavigate();
//   const { isLoggedIn, user, token } = useAuth();

//   useEffect(() => {
//     // Check if it's a reload and skip redirect if true
//     const isReload = sessionStorage.getItem("reloadPrevention");
//     sessionStorage.setItem("reloadPrevention", "true");

//     if (!cardId && !isReload) {
//       navigate("/digital-catalog");
//       return;
//     }

//     const checkAccess = async () => {
//       if (!user) {
//         setIsLoading(false);
//         throw new Error("User not logged in");
//       }

//       console.log("Checking product access...");
//       const productResponse = await fetch("https://auth.ssccglpinnacle.com/api/checkAccessProduct", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           "Accept": "application/json",
//           "Authorization": `Bearer ${token}`
//         },
//         body: JSON.stringify({ product_id: cardId })
//       });

//       if (!productResponse.ok) {
//         throw new Error(`Failed to check product access. Status: ${productResponse.status}`);
//       }

//       const { access } = await productResponse.json();
//       console.log("Product access result:", access);

//       if (access === "access") {
//         console.log("User has access to the product.");
//         return true;
//       } else {
//         console.log("User does not have access, checking free trial...");
//         const ebookResponse = await fetch(`https://auth.ssccglpinnacle.com/api/ebooksforactive`);
//         if (!ebookResponse.ok) {
//           throw new Error(`Failed to fetch ebooks. Status: ${ebookResponse.status}`);
//         }
//         const ebookData = await ebookResponse.json();
//         const ebook = ebookData.find(ebook => ebook._id === cardId);

//         if (!ebook) {
//           console.log("Ebook not found, redirecting to catalog...");
//           navigate("/digital-catalog");
//           return false;
//         }

//       //   if (ebook.free.some(entry => entry.user === user._id && entry.tryForFree)) {
//       //     console.log("User has already tried for free.");
//       //     return true;
//       //   } else {
//       //     console.log("User has not tried for free, allowing 5 minutes access...");
//       //     const timer = setTimeout(() => {
//       //       navigate(`/digital-catalog?cardId=${cardId}`);
//       //     }, 5 * 60 * 1000); // 5 minutes in milliseconds

//       //     await updateTryForFreeStatus();
//       //     return () => clearTimeout(timer);
//       //   }
//       // }

//       if (ebook.free.some(entry => entry.user === user._id && entry.tryForFree)) {
//         console.log("User has already tried for free.");
//         return true;
//       } else {
//         console.log("User has not tried for free, allowing 5 minutes access...");
        
//         const timer = setTimeout(async () => {
//           await updateTryForFreeStatus();
//           navigate(`/digital-catalog?cardId=${cardId}`);
//         }, 5 * 60 * 1000); // 5 minutes in milliseconds
        
//         return () => clearTimeout(timer);
//       }
//     }      
//     };

//     const updateTryForFreeStatus = async () => {
//       console.log("Updating try for free status...");
//       const response = await fetch("https://auth.ssccglpinnacle.com/api/updateTryForFree-ebook", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           "Authorization": `Bearer ${token}`
//         },
//         body: JSON.stringify({
//           userId: user._id,
//           cardId: cardId,
//           tryForFree: true,
//         }),
//       });

//       if (!response.ok) {
//         throw new Error(`HTTP error! Status: ${response.status}`);
//       }

//       const responseData = await response.json();
//       console.log("Try for free status updated:", responseData);
//     };

//     const fetchChapterData = async () => {
//       try {
//         const hasAccess = await checkAccess();
//         if (!hasAccess) return;

//         const response = await fetch(`https://auth.ssccglpinnacle.com/api/chapters-ebook?ebook=${cardId}`, {
//           headers: {
//             "Content-Type": "application/json",
//             "Accept": "application/json",
//             "Authorization": `Bearer ${token}`
//           },
//         });
//         if (!response.ok) {
//           throw new Error("Error fetching chapter data");
//         }
//         const data = await response.json();
//         setChapterData(data);
//         setIsLoading(false);

//         const chaptersForEbook = data.filter(chapter => chapter.ebook._id === cardId);

//         if (chaptersForEbook.length === 0 || !chaptersForEbook[0].title) {
//           Swal.fire({
//             icon: "warning",
//             title: "Data Missing",
//             text: "You must add the data because the title is not available.",
//           });

//           navigate("/digital-catalog");
//         }
//       } catch (error) {
//         console.error("Error fetching chapter data:", error);
//         setIsLoading(false);
//       }
//     };

//     if (cardId && isLoggedIn) {
//       fetchChapterData();
//     } else {
//       setIsLoading(false);
//     }
//   }, [cardId, navigate, user, token, isLoggedIn]);

//   if (!cardId) {
//     return <Navigate to="/digital-catalog" replace />;
//   }

//   if (isLoading) {
//     return <LoadingSpinner />;
//   }

//   return (
//     <div className={`my-component ${theme === "dark" ? "dark-theme" : "light-theme"}`}>
//       <HomeEbook chapterData={chapterData} cardId={cardId} />
//       {/* Add Footer component here if needed */}
//     </div>
//   );
// };

// export default DigitalMainPage;

import React, { useEffect, useState, useContext, useRef } from "react";
import { useParams, Navigate, useNavigate } from "react-router-dom";
import HomeEbook from "./Ebook-Page/Home";
import { ThemeContext } from "../../Context/ThemeContext";
import "./Ebook-Page/Navigation.css";

import Footer from "../../component/Footer/Footer";
import { useAuth } from "../../Context/AuthContext";
import Swal from "sweetalert2";
import LoadingSpinner from "../LoadingSpinner";

const DigitalMainPage = () => {
  const { cardId } = useParams();
  const [chapterData, setChapterData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { theme } = useContext(ThemeContext);
  const navigate = useNavigate();
  const { isLoggedIn, user, token } = useAuth();

  const timerRef = useRef(null); // To store the timer reference

  useEffect(() => {
    const isReload = sessionStorage.getItem("reloadPrevention");
    sessionStorage.setItem("reloadPrevention", "true");

    // If redirect flag is set for this ebook, don't allow free access
    const hasRedirected = localStorage.getItem(`redirect_${cardId}`);

    if (!cardId && !isReload) {
      navigate("/digital-catalog");
      return;
    }

    const checkAccess = async () => {
      if (!user) {
        setIsLoading(false);
        throw new Error("User not logged in");
      }

      console.log("Checking product access...");
      const productResponse = await fetch("https://auth.ssccglpinnacle.com/api/checkAccessProduct", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify({ product_id: cardId }),
      });

      if (!productResponse.ok) {
        throw new Error(`Failed to check product access. Status: ${productResponse.status}`);
      }

      const { access } = await productResponse.json();
      console.log("Product access result:", access);

      // If user has purchased access, allow them to view the page
      if (access === "access") {
        console.log("User has access to the product.");
        return true;
      } else if (hasRedirected) {
        // If the user has been redirected before and hasn't purchased access, block them
        console.log("User has already been redirected after free trial.");
        navigate(`/digital-catalog?cardId=${cardId}`);
        return false;
      } else {
        console.log("User does not have access, checking free trial...");

        // Start free trial timer and redirect after 5 minutes if no purchase
        const timer = setTimeout(async () => {
          await updateTryForFreeStatus();
          localStorage.setItem(`redirect_${cardId}`, 'true'); // Set redirect flag in localStorage
          navigate(`/digital-catalog?cardId=${cardId}`);
        }, 5 * 60 * 1000); // 5 minutes in milliseconds

        // Save the timer reference
        timerRef.current = timer;

        return () => clearTimeout(timerRef.current); // Clear the timeout if unmounting
      }
    };

    const updateTryForFreeStatus = async () => {
      console.log("Updating try for free status...");
      const response = await fetch("https://auth.ssccglpinnacle.com/api/updateTryForFree-ebook", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify({
          userId: user._id,
          cardId: cardId,
          tryForFree: true,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      console.log("Try for free status updated:", responseData);
    };

    const fetchChapterData = async () => {
      try {
        const hasAccess = await checkAccess();
        if (!hasAccess) return;

        const response = await fetch(`https://auth.ssccglpinnacle.com/api/chapters-ebook?ebook=${cardId}`, {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error("Error fetching chapter data");
        }
        const data = await response.json();
        setChapterData(data);
        setIsLoading(false);

        const chaptersForEbook = data.filter(chapter => chapter.ebook._id === cardId);

        if (chaptersForEbook.length === 0 || !chaptersForEbook[0].title) {
          Swal.fire({
            icon: "warning",
            title: "Data Missing",
            text: "You must add the data because the title is not available.",
          });

          navigate("/digital-catalog");
        }
      } catch (error) {
        console.error("Error fetching chapter data:", error);
        setIsLoading(false);
      }
    };

    if (cardId && isLoggedIn) {
      fetchChapterData();
    } else {
      setIsLoading(false);
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current); // Clear timeout on component unmount
        timerRef.current = null;
      }
    };
  }, [cardId, navigate, user, token, isLoggedIn]);

  if (!cardId) {
    return <Navigate to="/digital-catalog" replace />;
  }

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className={`my-component ${theme === "dark" ? "dark-theme" : "light-theme"}`}>
      <HomeEbook chapterData={chapterData} cardId={cardId} />
      {/* Add Footer component here if needed */}
    </div>
  );
};

export default DigitalMainPage;











