// import React, { useState, useEffect } from "react";
// import { useAuth } from "../../Context/AuthContext";
// import Swal from "sweetalert2";
// import { useNavigate, useParams } from "react-router-dom";
// import BillingForm from "./BillingForm"; // Adjust the path as needed
// import "./VerifyAddress.css";

// function VerifyAddress() {
//   const { isLoggedIn, user } = useAuth();
//   const { cardId, bookprice } = useParams();
//   const navigate = useNavigate();
//   const [billingInfo, setBillingInfo] = useState(null);
//   const [editMode, setEditMode] = useState(false);


//   useEffect(() => {
//     const fetchBillingInfo = async () => {
//       try {
//         const response = await fetch(`https://auth.ssccglpinnacle.com/api/bookBilling/${user._id}`);
//         if (response.ok) {
//           const data = await response.json();
//           setBillingInfo(data);
//         } else {
//           setBillingInfo(null);
//         }
//       } catch (error) {
//         console.error("Error fetching billing info:", error);
//         setBillingInfo(null);
//       }
//     };

//     fetchBillingInfo();
//   }, [user._id]);

//   const handleEditBilling = () => {
//     setEditMode(true);
//   };

//   const handleBuyNow = async () => {
//     try {
//       const response = await fetch(`https://auth.ssccglpinnacle.com/api/purchase-book/${user._id}`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ cardId, billingInfo }),
//       });
//       if (response.ok) {
//         Swal.fire({
//           icon: 'success',
//           title: 'Success',
//           text: 'Purchase completed successfully!',
//         });
//         navigate('/purchase-success'); // Redirect to a success page
//       } else {
//         Swal.fire({
//           icon: 'error',
//           title: 'Error',
//           text: 'Failed to complete the purchase. Please try again.',
//         });
//       }
//     } catch (error) {
//       console.error('Error completing purchase:', error);
//       Swal.fire({
//         icon: 'error',
//         title: 'Error',
//         text: 'Failed to complete the purchase. Please try again.',
//       });
//     }
//   };

//   const handleSaveBilling = (updatedBillingInfo) => {
//     setBillingInfo(updatedBillingInfo);
//     setEditMode(false);
//   };

//   if (!isLoggedIn) {
//     navigate('/');
//   }

//   return (
//     <div className="billing-info">
//       {billingInfo && !editMode ? (
//         <>
//           <h2>Billing Information</h2>
//           <p><strong>Full Name:</strong> {billingInfo.fullName}</p>
//           <p><strong>Mobile Number:</strong> {billingInfo.mobileNumber}</p>
//           <p><strong>Address:</strong> {billingInfo.address}</p>
//           <p><strong>State:</strong> {billingInfo.state}</p>
//           <p><strong>City:</strong> {billingInfo.city}</p>
//           <p><strong>Pincode:</strong> {billingInfo.pincode}</p>
//           <button onClick={handleEditBilling}>Edit Address</button>
//           <button onClick={handleBuyNow}>Buy Now</button>
//         </>
//       ) : (
//         <BillingForm
//           onClose={() => setEditMode(false)}
//           initialData={billingInfo}
//           onSave={handleSaveBilling}
//         />
//       )}
//     </div>
//   );
// }

// export default VerifyAddress;


import React, { useState, useEffect } from "react";
import { useAuth } from "../../Context/AuthContext";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import BillingForm from "./BillingForm";
import BookBuyButton from "./BookBuyButton";
import "./VerifyAddress.css";

function VerifyAddress() {
  const { isLoggedIn, user } = useAuth();
  const { cardId } = useParams();
  const navigate = useNavigate();
  const [billingInfo, setBillingInfo] = useState(null);
  const [editMode, setEditMode] = useState(false);

  // Fetch billing info once the component is mounted and when user._id changes
  useEffect(() => {
    if (user && user._id) { // Ensure user data is available before fetching
      const fetchBillingInfo = async () => {
        try {
          const response = await fetch(`https://auth.ssccglpinnacle.com/api/bookBilling/${user._id}`);
          if (response.ok) {
            const data = await response.json();
            setBillingInfo(data);
          } else {
            setBillingInfo(null);
          }
        } catch (error) {
          console.error("Error fetching billing info:", error);
          setBillingInfo(null);
        }
      };

      fetchBillingInfo();
    }
  }, [user]);

  const handleEditBilling = () => {
    setEditMode(true);
  };

  const handleSaveBilling = (updatedBillingInfo) => {
    setBillingInfo(updatedBillingInfo);
    setEditMode(false);
  };

  if (!isLoggedIn) {
    navigate('/');
    return null; // Return null to prevent rendering if the user is not logged in
  }

  return (
    <div className="billing-info">
      {billingInfo && !editMode ? (
        <>
          <h2>Billing Information</h2>
          <p><strong>Full Name:</strong> {billingInfo.fullName}</p>
          <p><strong>Mobile Number:</strong> {billingInfo.mobileNumber}</p>
          <p><strong>Address:</strong> {billingInfo.address}</p>
          <p><strong>State:</strong> {billingInfo.state}</p>
          <p><strong>City:</strong> {billingInfo.city}</p>
          <p><strong>Pincode:</strong> {billingInfo.pincode}</p>
          <button onClick={handleEditBilling}>Edit Address</button>
          <BookBuyButton
            productId={cardId}
            userDetails={{
              userId: user._id,
              userName: user.full_name,
              userEmail: user.email_id,
              userMobileNo: billingInfo?.mobileNumber,
            }}
            buttonText="Buy Now"
          />
        </>
      ) : (
        <BillingForm
          onClose={() => setEditMode(false)}
          initialData={billingInfo}
          onSave={handleSaveBilling}
        />
      )}
    </div>
  );
}

export default VerifyAddress;
