import { Row, Col, Button } from "react-bootstrap";
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import './Typing.css';

const TypingExamSelection = () => {
  const [exam, setExam] = useState('');
  const [category, setCategory] = useState('');
  const navigate = useNavigate();
  const [cookies] = useCookies(['token']);

  
  const onChangeExam = e => {
    setExam(e.target.value);
  };

  const onChangeCategory = e => {
    setCategory(e.target.value);
  };

  const next = async () => {
    if (exam !== '' && category !== '') {
      navigate(`/typingparagraph/${exam}/${category}`);
    }
  };

  useEffect(() => {
    const checkAccess = async () => {
      if (!cookies.token) {
        navigate('/');
        return;
      }

      try {
        const response = await fetch('https://auth.ssccglpinnacle.com/api/checkAccessTyping', {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": `Bearer ${cookies.token}`
          }
        });

        if (response.ok) {
          const { access } = await response.json();
          if (access === "access") {
            const productResponse = await fetch('https://auth.ssccglpinnacle.com/api/checkAccessTypingProduct', {
              method: 'POST',
              headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${cookies.token}`
              },
              body: JSON.stringify({ product_id: "999" }) // Replace with actual product ID
            });

            if (productResponse.ok) {
              const { access } = await productResponse.json();
              if (access === "access") {
                return; // User has access, do nothing
              } else {
                navigate('/');
              }
            } else {
              navigate('/');
            }
          } else {
            navigate('/');
          }
        } else {
          navigate('/');
        }
      } catch (error) {
        navigate('/');
      }
    };

    checkAccess();
  }, [cookies.token, navigate]);

  return (
    <>
      <Row className="tm-fr">
        <Col md={12} className="tm-fr-col12">
          <p className="tm-fr-col12p">TCS Typing Software 2024</p>
        </Col>
      </Row>
      <Row className="tm-secr">
        <Col md={4} className="tm-secr-col6">
          <p className="tm-secr-col6-p1">System Name:</p>
          <p className="tm-secr-col6-p2">C005</p>
          <p className="tm-secr-col6-p3">Contact Invigilator if the Name and Photograph <br /> display on the screen is not yours.</p>
        </Col>
        <Col md={4} className="tm-secr-col4">
          <p className="tm-secr-col6-p1">This typing Software is designed based on the real interface of ssc exams conducted by TCS</p>
        </Col>
        <Col md={4} className="tm-secr-2col6">
          <Col className="tm-secr-50">
            <p className="tm-secr-2col6-p1">Candidate Name:</p>
            <p className="tm-secr-2col6-p2">User 05</p>
            <p className="tm-secr-2col6-p3">Subject: <span className="tm-secr-2col6-p3-span">SSC CGL T2 English</span></p>
          </Col>
        </Col>
      </Row>
      <Row className="tp-second">
        <Col md={4}></Col>
        <Col md={3} className="tp-second-col3 examname">
          <h5 className="form-h5">Choose Exam And Category</h5>
          <form>
            <Row className="tp-second-col3-row1">
              <select className="tp-second-col3-row1-select" onChange={onChangeExam} required>
                <option>Select</option>
                <option>CGL</option>
                <option>CHSL</option>
              </select>
            </Row>
            <Row className="tp-second-col3-row3">
              <select className="tp-second-col3-row1-select" onChange={onChangeCategory} required>
                <option>Select</option>
                <option>UR</option>
                <option>OBC</option>
                <option>SC</option>
                <option>EWS</option>
              </select>
            </Row>
            <Row className="tp-second-col3-row3">
              <input type="button" className="form-input3" value="Next" onClick={next} />
            </Row>
          </form>
        </Col>
        <Col md={5}></Col>
      </Row>
    </>
  );
};

export default TypingExamSelection;
