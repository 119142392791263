import Row1Digital from './Row1DigitalBook'
import Header from "../../componentbook/HeaderFilesBook/HeaderBook";
import Row2Digital from "./Row2DigitalBook"
import Row4Digital from "./Row4DigitalBook"
import Row5Digital from "./Row5DigitalBook"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import "./Body.css";
import DataTransferContext from "../../DataTransferContext";
import React, { useContext, useEffect, useState } from "react";



function BodyBook() {
  const [filteredEbookData, setFilteredEbookData] = useState([]);

  // Define a function to receive filtered ebook data from Row1Digital
  const receiveFilteredEbookData = (data) => {
    setFilteredEbookData(data);
  };

  // console.log("row1" + filteredEbookData)

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <div className="main-body-container">
      <Header />

      <div className="Digital-Body ">
        <Row1Digital onDataFiltered={receiveFilteredEbookData}/>
        <Row2Digital filteredEbookData={filteredEbookData}/>
      </div>
      <div className="Digital-body1">
      <Row4Digital filteredEbookData={filteredEbookData}/>
        <Row5Digital filteredEbookData={filteredEbookData}/>
      </div>
      </div>
    </>
  );
}

export default BodyBook;
