import React, { useState, useEffect } from 'react';
import "../PagesBook/DigitalContentBody/Row5Digital.css";
import Rate from "../componentbook/StarBook/RateBook";
import { useAuth } from "../Context/AuthContext";
import { useNavigate } from "react-router-dom";
import BillingForm from '../PagesBook/DigitalContentBody/BillingForm';
import { AiFillStar } from "react-icons/ai";
import amazon from "../iBook/amazon.svg";
import flipkart from "../iBook/flipkart.svg";
import pinnacle from "../iBook/pinnaclelogo.png";
import { FcApproval } from "react-icons/fc";
import Swal from "sweetalert2";

function Recentlylaunch() {
  const [cardData, setCardData] = useState([]);
  const { isLoggedIn, user } = useAuth();
  const [hoveredId, setHoveredId] = useState(null);
  const navigate = useNavigate();
  const [showBillingForm, setShowBillingForm] = useState(false);

  useEffect(() => {
    const fetchEbooksData = async () => {
      try {
        const ebookResponse = await fetch(
          "https://auth.ssccglpinnacle.com/api/booksforactive?active=true"
        );
        if (!ebookResponse.ok) {
          throw new Error(
            `Failed to fetch ebooks. Status: ${ebookResponse.status}`
          );
        }
        const ebookData = await ebookResponse.json();
        if (ebookData && ebookData.length > 0) {
          // Set cardData to filteredEbookData if it meets the conditions
          const finalEbookData = ebookData && ebookData.length > 0 ? ebookData : ebookData;
          
          finalEbookData.reverse(); // This reverses the array in place

          setCardData(finalEbookData);
      } 
      } catch (error) {
        console.error("Error fetching ebook data:", error);
      }
    };

    fetchEbooksData();
  }, [cardData]);

  const info = (cardId) => {
    navigate(`/intro-book/${cardId}`);
  };

  const getRatingForCard = (cardId) => {
    const card = cardData.find((card) => card._id === cardId);

    if (!card || !card.rating || card.rating.length === 0) {
      return 0;
    }

    return calculateAverageRating(card.rating);
  };

  const calculateAverageRating = (ratings) => {
    const totalRating = ratings.reduce(
      (sum, rating) => sum + rating.starRate,
      0
    );
    return totalRating / ratings.length;
  };

  const handleBuyNow = async (bookId) => {
    if (!isLoggedIn) {
      Swal.fire({
        icon: "info",
        title: "Note",
        text: "Please log in to buy this item.",
      });
      return;
    }

    try {
      const response = await fetch(
        `https://auth.ssccglpinnacle.com/api/bookBilling/${user._id}`
      );
      if (response.ok) {
        const data = await response.json();
        if (data) {
          navigate(`/verify-address/${bookId}`);
        } else {
          
          setShowBillingForm(true);
          document.body.style.overflow = "hidden"; 
        }
      } else {
        // Handle case where fetching billing information fails
        console.error("Failed to fetch billing information");
        setShowBillingForm(true);
        document.body.style.overflow = "hidden"; 
      }
    } catch (error) {
      console.error("Error fetching billing information:", error);
      setShowBillingForm(true);
      document.body.style.overflow = "hidden"; 
    }
  };

  const calculateDiscount = (BookSellingPrice, BookPrintingPrice) => {
    const savingAmount = BookPrintingPrice - BookSellingPrice;
    const discountPercentage = (savingAmount / BookPrintingPrice) * 100;
    return { savingAmount, discountPercentage };
  };
  const closeBillingForm = () => {
    setShowBillingForm(false);
    document.body.style.overflow = "";
  };

  
  return (
    <>
      <div className="section-suggest-book">
        <div className="container-fluid">
          <div className="foruppersectionebook">
            <div className="col-md-8 section-suggest-book-col-8">
              <p className="col-md-8 section-suggest-book-p">
                Recently launched ebook
              </p>
            </div>
          </div>

          <div className="container-digital-launced-ebook">
            <ul className="cards-digital-launced-ebook">
            {cardData && cardData.length > 0 ? (
  cardData.slice(0, 5).map((card) => {
    const { savingAmount, discountPercentage } = calculateDiscount(
      card.BookSellingPrice,
      card.BookPrintingPrice
    );
    return (
                  <li key={card._id} className="card-digital-ebook">
                    <div className="upper-card-digital">
                      <div className="card-content-digital-ebook">
                        <div
                          className="for-image-size"
                          onClick={() => info(card._id)}
                        >
                          <img
                            className="card-title-digital-ebook"
                            src={card.image1}
                            alt={card.title}
                          />
                        </div>
                      </div>

                      <div className="card-link-wrapper-digital-ebook">
                        <p>
                          {card.title}
                          {/* {card.title} {card.medium} medium {card.edition} edition */}
                        </p>
                      </div>

                      <div className="buttons-for-buying-products">
                        <div className="button-for-everything">
                        <div className="try-fot-free-books-only">
                          <div className="try-for-free">
                            <div
                              className="Rating"
                              onMouseOver={() => setHoveredId(card._id)}
                              onMouseOut={() => setHoveredId(null)}
                            >
                              <div className="Star-Rating-row-5">
                                {getRatingForCard(card._id).toFixed(1)}
                                <div className="For-Star-div">
                                  <AiFillStar className="Star-Rating-1" />
                                </div>
                              </div>
                              <div className="Total-Rating">
                                ({card.rating.length})
                              </div>
                            </div>
                          </div>
                          <div className="StarRating">
                            {hoveredId === card._id && (
                              <Rate cardData={card} />
                            )}
                          </div></div>
                          <div className="price-for-billing">
  <span className="price-for-billing__selling-price">
    ₹ {card.BookSellingPrice} incl. GST
  </span>
  <div className="price-for-billing__details">
    <span className="price-for-billing__mrp">
      M.R.P.: <del>₹ {card.BookPrintingPrice}</del>
    </span>
    <span className="price-for-billing__saving-amount">
      Save: ₹ {savingAmount.toFixed(2)} ({discountPercentage.toFixed(0)}%)
    </span>
  </div>
</div>
                        </div>
                        <div className="buttons-for-important">
                          <div className="button-for-try-view-amazon-link">
                            <div className="amazon-link-row3">
                              <img
                                src={amazon}
                                className="amazon-link-row3-videoicon"
                              />
                            </div>
                            <div className="amazon-link-button">
                              <a
                                className="buynow-for-user1-row4"
                                href={card.amazonLink}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Buy Now
                              </a>
                            </div>
                          </div>
                          <div className="button-for-try-view-flipkart-link">
                            <div className="flipkart-link-row3">
                              <img
                                src={flipkart}
                                className="flipkart-link-row3-videoicon"
                              />
                            </div>
                            <div className="amazon-link-button">
                              <a
                                className="buynow-for-user1-row4"
                                href={card.flipkartLink}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Buy Now
                              </a>
                            </div>
                          </div>
                          <div className="button-for-try-view-pinnacle-link">
                            <div className="pinnacle-link-row3">
                              <img
                                src={pinnacle}
                                className="pinnacle-link-row3-videoicon"
                              />
                            </div>
                            <button
                              className="buynow-for-user1-row4"
                              onClick={() => {
                                if (!isLoggedIn) {
                                  window.location.href = 'https://testportal.ssccglpinnacle.com/login';
                                } else {
                                  handleBuyNow(card._id);
                                }
                              }}
                            >
                              Buy Now
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                 );
                })
              ) : (
                <p>No data available</p> // Display a message or a placeholder when there is no data
              )}
            </ul>
          </div>
        </div>
      </div>
      {showBillingForm && <BillingForm onClose={closeBillingForm} />}
    </>
  );
}

export default Recentlylaunch;
